import { cx } from "@linaria/core";
import { useRadioState } from "reakit";
import { forwardRef, memo, ReactNode, useEffect } from "react";
import { Loader } from "@/ui/Loaders/ComponentUiLoader/Loader";
import { cssIsDisabled, cssMockInput, cssRadioButtons, cssRadioGroup, cssRadioRounds, cssRadioTabs, RadioLabel, StyledRadio, StyledRadioBox, StyledRadioGroup, StyledRadioGroupWrap, StyledRadioInner, StyledRadioMessage } from "./StyledRadio";
import { RadioGroupItemsType } from "../../../types/types";
import { ErrorMessageField } from "../Typography/Typography";
export type RadioGroupOnChangeType = (value: string | number | undefined) => void;
export type RadioGroupWithInitialStatePropsType = {
  indexDefault?: number;
  ariaLabel?: string;
};
export type RadioGroupPropsType = {
  items: RadioGroupItemsType[];
  variant: "tabs" | "buttons" | "rounds";
  ariaLabel?: string;
  onChange?: RadioGroupOnChangeType;
  indexCurrent?: number | null;
  itemsElements?: Record<string, {
    content: ReactNode;
  }>;
  errorMessage?: string;
  disabled?: boolean;
  isForceUpdate?: boolean;
};
const classesCompare = {
  rounds: cssRadioRounds,
  tabs: cssRadioTabs,
  buttons: cssRadioButtons
};
export const CustomRadioGroup = memo(forwardRef<HTMLInputElement, RadioGroupWithInitialStatePropsType & RadioGroupPropsType & {
  isFetching?: boolean;
}>(({
  variant,
  items,
  itemsElements,
  ariaLabel = "",
  onChange,
  indexCurrent,
  indexDefault = -1,
  isFetching,
  errorMessage,
  disabled = false,
  isForceUpdate = false
}, ref) => {
  const {
    move,
    items: itemsRadioState,
    state,
    setState,
    ...radio
  } = useRadioState({
    state: items.length > 0 && indexDefault >= 0 ? items[indexDefault || 0].value : undefined
  });
  useEffect(() => {
    if (indexCurrent === null) {
      setState(undefined);
      move(null);
    } else {
      if (indexCurrent !== undefined) {
        if (indexCurrent === -1) {
          setState(undefined);
        } else {
          if (itemsRadioState[indexCurrent] !== undefined) {
            move(itemsRadioState[indexCurrent].id);
          }
        }
      }
    }
  }, [indexCurrent, move, itemsRadioState, setState, isForceUpdate]);
  useEffect(() => {
    if (onChange && !!state) {
      onChange(state);
    }
  }, [state]);
  return <StyledRadioGroupWrap className={cssRadioGroup} data-is-error={!!errorMessage}>
          <input type="text" className={cssMockInput} ref={ref} disabled={disabled} />
          <StyledRadioGroup data-variant={variant} {...radio} state={state} className={cx(classesCompare[variant])} aria-label={ariaLabel}>
            {isFetching && <Loader />}
            {items.map((item, index) => <RadioLabel key={index} className={cx(disabled && cssIsDisabled)}>
                <StyledRadio {...radio} state={state} setState={setState} value={item.value} disabled={disabled} aria-label={item.value.toString()} />{" "}
                {variant === "rounds" && <StyledRadioBox />}
                <StyledRadioInner>
                  {itemsElements !== undefined && !!itemsElements[item.value] ? <>{itemsElements[item.value].content}</> : <StyledRadioMessage>{item.message}</StyledRadioMessage>}
                </StyledRadioInner>
              </RadioLabel>)}
          </StyledRadioGroup>
          {errorMessage && <ErrorMessageField>{errorMessage}</ErrorMessageField>}
        </StyledRadioGroupWrap>;
}));
CustomRadioGroup.displayName = "CustomRadioGroup";