import { MACROS_ERROR_COUNT } from "@/components/Products/parts"
import { IgetValueFX } from "@/components/Products/parts/Counter/types"

export const getCounterMsg = (message: string, replace?: string): string =>
  message.replace(MACROS_ERROR_COUNT, `<NoWrap>${replace}</NoWrap>`)

export const getValue = ({
  currentUnit,
  initialUnit,
  maxCount,
  count,
}: IgetValueFX): number => {
  // если переключились на увелечение по коробкам, прибавляем продукт по количеству в коробке.
  //но если 2 коробки превышают остаток, прибавляем количество по количеству в пачке
  let value = count + currentUnit

  if (initialUnit !== currentUnit && count + currentUnit > maxCount) {
    if (count === null || initialUnit === null) {
      return value
    }

    value = count + initialUnit
  }

  return value
}
